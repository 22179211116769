<template>
  <section id="terms">
    <v-container>
      <div class="body">
        <h1>Terms of Service</h1>
      <br />
        <p>
          Thanks for using intheloopstudio.com web services. These terms of
          service (&quot;Agreement&quot;) cover your use and access to the
          services, client software and websites (&quot;Services&quot;) provided
          by In The Loop. Our Privacy explains how we collect and use your
          information while our Acceptable Use and Fair Usage Policy outlines
          your responsibilities when using our Services.
        </p>
        <p>
          By using our Services, you&#39;re agreeing to be bound by the terms of
          this Agreement, and to review our Privacy Policy and Acceptable Use
          and Fair Usage Policy.
        </p>
        <p>
          By using or accessing the Services, you agree to all the terms and
          conditions stated in this Agreement. If, at any time, you do not or
          cannot agree to the terms of this Agreement, you must stop accessing
          or using the Services.
        </p>
        <br />
        <h3 id="your-assets-and-your-permissions">
          Your Assets and Your Permissions
        </h3>
        <p>
          When you use our Services, you provide us with things like your files,
          content, configuration settings, Metadata and so on (&quot;Your
          Assets&quot;). Your Assets are yours. The terms of this Agreement
          don&#39;t give us any rights to Your Assets except for the limited
          rights that enable us to offer the Services.
        </p>
        <p>
          If you want to use our Release Service or Samples Service for your
          Recordings (as defined below), please carefully review all applicable
          provisions of the terms of this Agreement.
        </p>
        <p>
          We need your permission to do things like hosting Your Assets, backing
          them up, and sharing them when you ask us to. Our Services also
          provide you with features like song previews, editing, sharing,
          searching and releasing. These and other features may require our
          systems to access, store and scan Your Assets. We may also utilize
          Your Data (as defined in our Privacy Policy) to conduct investigations
          and studies, test functionalities during the development phase and
          analyze the information at our disposal to assess and improve our
          Services, implement new services and functionalities and perform
          audits and troubleshooting procedures, or for promotional purposes.
          You give us permission to do these things, and this permission extends
          to trusted third parties we work with.
        </p>
        <p>
          Once you access our Services via an offer or link provided by a In The
          Loop partner, it is likely that we will share some of Your Data with
          said partner, more specifically, your name, email address,
          subscription plan to our Services and information regarding the usage
          that you make of our Services. You authorize us to share such
          information with the aforementioned partner. The Data shared with this
          partner will also be governed by conditions of use and this partner’s
          privacy policy.
        </p>
        <br />
        <h3 id="sharing-your-assets">Sharing Your Assets</h3>
        <p>
          Our Services let you share Your Assets with others, so please think
          carefully about what you share. You understand that you assume all
          risks, including unwanted public release, related to your decision to
          share Your Assets. When you share Your Assets, you’re responsible for
          ensuring that those accessing Your Assets understand what they’re
          allowed to do, or not do, with Your Assets. Your sharing of Your
          Assets via our Services will not entitle you to any payments from any
          persons participating in our Services.
        </p>
        <p>
          When content is shared to you, offering comments, suggestions, and/or
          feedback to another user’s content on the In The Loop site does not
          mean that you acquire ownership rights or credits in that content nor
          does it mean that you shall have a right to any royalties or other
          monetary payments derived from that content. Without written approval
          from the party owning the content that was shared with you, you cannot
          share or reproduce the other user’s content. To obtain more
          information about your rights as a contributor, songwriter, or
          performer, please consult your performing rights organization,
          publisher, and/or legal counsel.
        </p>
        <br />
        <h3 id="your-recordings">Your Recordings</h3>
        <p>
          By a “Recording”, we mean a musical sound or audiovisual recording
          (including, unless otherwise stated herein, in the case of a sound
          recording, any music, lyrics and instrumental and/or vocal
          performances recorded thereon, including any Samples as may be
          incorporated therein pursuant to the Samples Licence, as the case may
          be, and in the case of an audiovisual recording, any sound recording
          reproduced thereon and any script, direction, music and lyrics or
          other works, any likeness and/or voice, and any visual, audiovisual,
          instrumental and/or vocal performances recorded on such audiovisual
          recording or on any sound recording reproduced thereon), which is
          uploaded to your In The Loop account, and that you ask us to make
          available to the public through our App or Website Service.
        </p>
        <br />
        <h3 id="copyright-and-intellectual-property">
          Copyright and Intellectual Property
        </h3>
        <p>
          We respect the intellectual property of others and ask that you do
          too. You cannot upload, share, release or otherwise use on our
          Services any content, including any audio files, samples or
          Recordings, for which you do not own all necessary intellectual
          property rights, unless you have the express written consent from all
          persons owning the intellectual property rights in the content. If you
          don’t respect this rule, you assume all risks and liabilities arising
          therefrom, and we will have the right to delete any violating content
          on our Services. Further, you shall indemnify and save us harmless
          from and against any and all reasonable costs, expenses, losses,
          liabilities and damages (including, without limitation, court costs
          and counsel fees on a substantial indemnity basis) arising out of or
          connected with any claim arising from your submission to our Services
          of intellectual property you do not own or properly control. Without
          renouncing our other rights and remedies, we can also suspend or
          terminate your account.
        </p>
        <p>
          We respond to notices of alleged copyright infringement if they comply
          with the law, and such notices should be reported to us by email. We
          reserve the right to delete or disable content alleged to be
          infringing and terminate accounts of repeat infringers. Our designated
          agent for notice of alleged copyright infringement on the Services is:
          intheloopstudio2021@gmail.com
        </p>
      </div>
    </v-container>
  </section>
</template>

<script>
export default {
  name: "terms-of-service",
};
</script>

<style scoped>
#terms {
  padding: 0 !important;
  background-color: black;
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
}

.body {
  margin-top: 100px;
}
</style>